// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import { useNavigate } from "react-router-dom";

function Information() {
  const navigate = useNavigate();

  const handleScrollToBlog = () => {
    navigate("/#blog");
    setTimeout(() => {
      const blogSection = document.getElementById("blog");
      if (blogSection) {
        blogSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="analytics"
                    title="Análisis de Datos"
                    description="Utilizando IA y la tecnología autónoma las empresas obtienen con conocimiento valioso y accionable que mejorán la toma de decisiones."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="devices"
                    title="Aplicaciones Impulsadas por Datos"
                    description="Construimos aplicaciones que permiten generar insights, facilitando decisiones informadas y personalizadas para tu negocio."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="design_services"
                    title="Diseño Inteligente"
                    description="Implementamos diseño y visualización avanzada para interpretar grandes volúmenes de datos, logrando una experiencia intuitiva y centrada en el usuario."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="support_agent"
                    title="Soporte y Mantenimiento"
                    description="Brindamos soporte continuo, asegurando el rendimiento y la confiabilidad de tus aplicaciones mediante la detección temprana y solución de problemas."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            onClick={handleScrollToBlog}
            item
            xs={12}
            lg={4}
            sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}
          >
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Impulsa tu Negocio con Tecnología Inteligente"
              description="Descubre cómo nuestras soluciones en IA y análisis de datos pueden optimizar tus operaciones y facilitar la toma de decisiones estratégicas para un crecimiento sostenible."
              action={{
                type: "internal",
                // route: "/analisis-de-datos/ecosistema-de-datos-moderno",
                color: "info",
                label: "Más información",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
