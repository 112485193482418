// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";

// Images
import author from "assets/images/main/raulloyola.png";

// import blogs from "blogs";

function BlogPosts() {
  return (
    <MKBox component="section" py={7}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          mb={6}
        >
          <MKBox py={2} px={6} textAlign="center">
            <MKBox
              width="4rem"
              height="4rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="lg"
              mx="auto"
            >
              <Icon fontSize="medium">dashboard</Icon>
            </MKBox>
            <MKTypography variant="h2" mt={2} mb={1}>
              Tech & Data Blog
            </MKTypography>
            <MKTypography variant="body2" color="text">
              {/* We get insulted by others, lose trust for those others. We get back freezes every
              winter */}
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post1}
              category={{ color: "primary", label: "house" }}
              title="Shared Coworking"
              description="Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons."
              author={{
                image: author1,
                name: "Mathew Glock",
                date: "Posted on 28 February",
              }}
              action={{ type: "internal", route: "/pages/blogs/single-article" }}
            />
          </Grid> */}
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={
                "https://images.unsplash.com/photo-1599658880436-c61792e70672?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%"
              }
              category={{ color: "info", label: "Análisis de Datos" }}
              title="Ecosistema de datos moderno"
              description="Hay una creciente escasez en la oferta y una alta demanda de analistas de datos calificados. Aquí veremos las habilidades que el mercado no puede encontrar en analistas de datos son cada vez más críticas."
              author={{
                image: author,
                name: "Raul Loyola",
                date: "Noviembre 02, 2024",
              }}
              action={{ type: "internal", route: "/analisis-de-datos/ecosistema-de-datos-moderno" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={
                "https://plus.unsplash.com/premium_photo-1661434270550-fc467725a2ed?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              category={{ color: "info", label: "Análisis de Datos" }}
              title="Tipos de Análisis de Datos"
              description="Existen distintos tipos de análisis: Análisis Descriptivo, Análisis de Diagnóstico, Análisis Predictivo y Análisis Prescriptivo. Cada uno de estos enfoques ayuda a tomar decisiones informadas en diferentes etapas del proceso de análisis de datos."
              author={{
                image: author,
                name: "Raul Loyola",
                date: "Noviembre 04, 2024",
              }}
              action={{ type: "internal", route: "/analisis-de-datos/tipos-de-analisis-de-datos" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={
                "https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              category={{ color: "info", label: "Análisis de Datos" }}
              title="Tipos de Datos"
              description="Existen 3 tipos de datos que podemos analizar: Datos estructurados, Datos semi-estructurados y Datos no estructurados. Conocer cada uno de ellos nos ayudará a decidir qué software, herramientas, frameworks y procesos que utilizaremos."
              author={{
                image: author,
                name: "Raul Loyola",
                date: "Noviembre 12, 2024",
              }}
              action={{ type: "internal", route: "/analisis-de-datos/tipos-de-datos" }}
            />
          </Grid>
          {/* <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post3}
              category={{ color: "warning", label: "house" }}
              title="Shared Coworking"
              description="Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons."
              author={{
                image: author3,
                name: "Mathew Glock",
                date: "Posted on 28 February",
              }}
              action={{ type: "internal", route: "/pages/blogs/single-article" }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BlogPosts;
