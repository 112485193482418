import { useEffect, useRef, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import MKBox from "components/MKBox";

// Import particles configuration
import particlesInit from "particles/particlesInit";
import particlesOptions from "particles/particlesOptions";
import particlesLoaded from "particles/particlesLoaded";
import Rellax from "rellax";

const ParticlesLab = () => {
  const headerRef = useRef(null);
  const [init, setInit] = useState(false);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <>
      <MKBox
        ref={headerRef}
        minHeight="24vh"
        width="50%"
        sx={{
          display: "grid",
          placeItems: "center",
          margin: "auto",
          marginTop: "10vh",
        }}
      >
        {/* Particles background */}
        <MKBox
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        >
          {init && (
            <Particles
              id="tsparticles"
              particlesLoaded={particlesLoaded}
              init={particlesInit}
              options={particlesOptions}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </MKBox>
      </MKBox>
    </>
  );
};

export default ParticlesLab;
