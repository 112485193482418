// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

function ApplicationOne() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          textAlign="center"
          mx="auto"
          mb={3}
        >
          <MKTypography
            variant="body2"
            color="info"
            fontWeight="bold"
            textTransform="uppercase"
            textGradient
          >
            Servicios
          </MKTypography>
          <MKTypography variant="h3">Aplicaciones Impulsadas por Datos</MKTypography>
        </Grid>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="dark"
              image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-working.jpg"
              label="Desarrollo de Software"
              title="¡Explora y descubre datos clave!"
              description="Construimos aplicaciones impulsadas por datos que permiten acceder a insights profundos, facilitando decisiones informadas y personalizadas para tu negocio."
              action={{
                type: "internal",
                route: "/",
                label: "comenzar",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="dark"
              image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/toboshar.jpg"
              label="Diseño Inteligente"
              title="Encuentra patrones y optimiza procesos"
              description="Implementamos diseño y visualización avanzada para interpretar grandes volúmenes de datos, logrando una experiencia intuitiva y centrada en el usuario."
              action={{
                type: "internal",
                route: "/",
                label: "comenzar",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="dark"
              image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/umbrellas.jpg"
              label="Soporte y Mantenimiento"
              title="Detecta y soluciona problemas"
              description="Nuestro equipo brinda soporte continuo, asegurando el rendimiento y la confiabilidad de tus aplicaciones mediante la detección temprana y solución de problemas."
              action={{
                type: "internal",
                route: "/",
                label: "comenzar",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ApplicationOne;
