import { useEffect, useRef, useState } from "react";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
const bgImage =
  "https://images.unsplash.com/photo-1686428449887-9e8c859861ae?q=80&w=1793&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

function Header() {
  const typedJSRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        closeMenu();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["Análisis de Datos", "Desarrollo Web", "Software"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <MKBox component="header" position="relative">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography
              component={Link}
              href="/"
              variant="button"
              color="white"
              fontWeight="regular"
              py={0.8125}
              mr={2}
            >
              loy {"< >"} tech
            </MKTypography>
            <MKButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
              onClick={toggleMenu}
            >
              <MKBox component="i" color="white" className="fas fa-bars" />
            </MKButton>

            {/* Desktop Menu */}
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="/about-us"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                >
                  Nosotros
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#blog"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                >
                  Blog
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#services"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                >
                  Servicios
                </MKTypography>
              </MKBox>
            </MKBox>
            {/* Desktop Menu */}

            {/* Mobile Menu */}
            {isOpen && (
              <MKBox
                component="ul"
                display={{ xs: "block", lg: "none" }}
                p={0}
                my={0}
                sx={{
                  listStyle: "none",
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  background: "white",
                  borderRadius: "8px",
                  zIndex: 10,
                }}
              >
                <MKBox
                  component="li"
                  p={1}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <MKTypography
                    component={Link}
                    href="/about-us"
                    variant="button"
                    color="black"
                    fontWeight="regular"
                  >
                    Acerca de nosotros
                  </MKTypography>
                </MKBox>
                <MKBox
                  component="li"
                  p={1}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <MKTypography
                    component={Link}
                    href="#blog"
                    variant="button"
                    color="black"
                    fontWeight="regular"
                  >
                    Blog
                  </MKTypography>
                </MKBox>
                <MKBox
                  component="li"
                  p={1}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <MKTypography
                    component={Link}
                    href="#services"
                    variant="button"
                    color="black"
                    fontWeight="regular"
                  >
                    Servicios
                  </MKTypography>
                </MKBox>
              </MKBox>
            )}

            {/* Mobile Menu */}

            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="/"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <MKBox component="i" color="white" className="fab fa-facebook" />
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="/"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <MKBox component="i" color="white" className="fab fa-instagram" />
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.5),
              rgba(gradients.info.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {" "}
              <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
              Descubre cómo la IA y la tecnología autónoma transformarán las industrias.
            </MKTypography>
            <MKButton color="white" onClick={() => (window.location.href = "#blog")}>
              Saber más
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Header;
