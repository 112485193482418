import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

// Import particles configuration
import particlesInit from "particles/particlesInit";
import particlesOptions from "particles/particlesOptions";
import particlesLoaded from "particles/particlesLoaded";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "analisis-de-datos/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "analisis-de-datos/sections/Information";
// import Steps from "pages/Blogs/SingleArticle/sections/Steps";
// import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
// import Features from "pages/Blogs/SingleArticle/sections/Features";
// import Posts from "pages/Blogs/SingleArticle/sections/Posts";
// import Support from "pages/Blogs/SingleArticle/sections/Support";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
// import bgImage from "assets/images/bg5.jpg";

import blogs from "blogs";
import FooterThress from "pages/Home/sections/footer";

function BlogArticle() {
  const { slug } = useParams(); // Get the slug from URL params
  const blog = blogs.find((b) => b.slug === slug); // Find the matching blog by slug

  const headerRef = useRef(null);
  const [init, setInit] = useState(false);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Initialize particles engine with loadSlim
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  if (!blog) {
    return <div>Blog post not found.</div>; // Handle case where slug doesn't match any blog
  }

  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route:
              "https://wa.me/5541915469?text=Quiero%20ayuda%20con%20un%20proyecto%20de%20análisis%20de%20datos",
            label: "contactar",
            color: "info",
          }}
          transparent
          relative
        />
      </MKBox>

      <MKBox
        ref={headerRef}
        minHeight="24vh"
        width="100%"
        sx={{
          display: "grid",
          placeItems: "center",
        }}
        // sx={{
        //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
        //     `${linearGradient(
        //       rgba(gradients.dark.main, 0.8),
        //       rgba(gradients.dark.state, 0.8)
        //     )}, url(${bgImage})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   display: "grid",
        //   placeItems: "center",
        // }}
      >
        {/* Particles background */}
        <MKBox
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        >
          {init && (
            <Particles
              id="tsparticles"
              particlesLoaded={particlesLoaded}
              init={particlesInit}
              options={particlesOptions}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </MKBox>
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            {/* <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {blog.title}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              {blog.description}
            </MKTypography> */}
            {/* <MKTypography variant="h5" color="white" mt={2} mb={1}>
              Connect with us on
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
              </MKTypography>
            </MKBox> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information topic={blog.topic} title={blog.title} content={blog.content} />
        {/* <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        {/* <DefaultFooter content={footerRoutes} /> */}
        <FooterThress />
      </MKBox>
    </>
  );
}

export default BlogArticle;
