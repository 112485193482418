const blogs = [
  {
    slug: "ecosistema-de-datos-moderno",
    title: "Ecosistema de datos moderno",
    topic: "Análisis de Datos",
    description:
      "Hay una creciente escasez en la oferta y una alta demanda de analistas de datos calificados. Las habilidades que el mercado no puede encontrar en analistas de datos son cada vez más críticas.",
    content: `
    <h4>Oportunidades y Desafíos en una Profesión de Alta Demanda</h4><br />
    <p>
      Para resolver la falta de habilidades en análisis de datos, la recomendación es que las empresas revisen bien las habilidades que realmente
      necesitan. A veces, las habilidades que antes eran importantes ya no lo son tanto, y es clave ajustarse a lo que pide el mercado hoy. Muchas
      personas que buscan empleo desean avanzar en su carrera, aprender nuevas habilidades y tener claridad sobre sus posibilidades de
      crecimiento. También quieren asegurarse de que pueden mantenerse al día con los cambios rápidos en la tecnología y habilidades requeridas.
    </p>
    <a href="https://www.kdrtalentsolutions.com/data-analysis-skills-shortage-challenges-and-solutions" target="_blank">Lectura recomendada</a>
    <br /> <br />

    <h4>Trayectoria Profesional: Vertical o Diversificada</h4> <br />
    <h5>Vertical</h5>
    <p>
      Un analista de datos puede crecer de maneral vertical en su carrera, avanzando en posiciones especializadas dentro del análisis de datos. En
      este camino, puede progresar desde un puesto de <strong>Associate Data Analyst</strong> hasta convertirse en
      <strong>Principal Analyst</strong>. Cada paso representa un nivel de experiencia y responsabilidad mayor, donde el analista desarrolla una
      comprensión más profunda de los datos, mejora sus habilidades de análisis, y asume roles de liderazgo en proyectos y estrategias de datos.
      Este enfoque te permitirá tener un rol de influencia y autoridad dentro de las organizaciones en el área de datos.
    </p>
    <br />
      <p>Associate Data Analyst</p>
      <p>Data Analyst</p>
      <p>Senior Data Analyst</p>
      <p>Lead Data Analyst</p>
      <p>Principal Data Analyst</p>
    <br />

    <h5>Diversificada</h5>
    <p>
      Los analistas de datos también pueden optar por diversificarse y explorar otras disciplinas relacionadas:
      <strong>Data Engineering</strong>, <strong>Data Science</strong>, <strong>Business Analytics</strong> y
      <strong>Business Intelligence Analytics</strong>. Cada una de estas disciplinas aporta un enfoque distinto al ecosistema de datos: la
      ingeniería de datos, se encargan de la arquitectura y disponibilidad de los datos, la ciencia de datos se centra en el análisis predictivo y
      el machine learning, la analítica de negocio, en interpretar datos para tomar decisiones empresariales, el analista de negocio y la
      inteligencia de negocio, ambos se encargan de la recopilación y análisis de datos para apoyar la toma de decisiones estratégicas. Este
      camino te permitirá ampliar tu conocimiento en áreas complementarias y diversificar tus oportunidades profesionales.
    </p>`,
    publicationDate: "Noviembre 02, 2024",
    readingTime: "5 min",
  },
  {
    slug: "tipos-de-analisis-de-datos",
    title: "Tipos de Análisis de Datos",
    topic: "Análisis de Datos",
    description:
      "Existen distintos tipos de análisis: Análisis Descriptivo, Análisis de Diagnóstico, Análisis Predictivo y Análisis Prescriptivo. Cada uno de estos enfoques ayuda a tomar decisiones informadas en diferentes etapas del proceso de análisis de datos.",
    content: `
    <h4>¿Qué es el Análisis de Datos?</h4> <br />
    <p>
      El análisis de datos convierte datos sin procesar en información útil. Esto se da en 3 etapas. La primer etapa es el proceso de
      <strong>recolectar, limpiar y transformar</strong> los datos. La segunda etapa consiste en la interpretación de los datos. Y la última etapa
      es la entrega del informe de resultados.
    </p>
    <p>
      De esta manera se encuentran patrones y correlaciones entre los datos, lo cual nos permitirá generar conocimiento útil y conclusiones. Esto
      nos sirve para poder conocer el pasado y tomar decisiones.
    </p>
    <a href="https://aws.amazon.com/es/what-is/data-analytics/" target="_blank">Lectura recomendada</a>
    <br />
    <br />

    <h4>Tipos de Análisis de Datos</h4> <br />
    <h5>Análisis Descriptivo</h5>
    <p>
      Es básicamente mirar al pasado. Este tipo de análisis responde a la pregunta: <strong>¿Qué pasó?</strong>. El análisis de datos descriptivo
      proporciona información sobre eventos pasados en un periodo de tiempo.
    </p>

    <h5>Análisis de Diagnóstico</h5>
    <p>
      Igual se enfoca en el pasado, pero este análisis responde a la pregunta: <strong>¿Por qué pasó?</strong>. El análisis de diagnóstico toma la
      información generada del análisis descriptivo para profundizar y así encontrar la causa del resultado.
    </p>

    <h5>Análisis Predictivo</h5>
    <p>
      Analiza los datos históricos para poder responder a la pregunta: <strong>¿Qué pasará después?</strong>. El análisis predictivo no nos dirá
      lo que pasará en el futuro, pero nos da un pronóstico de lo que podría pasar.
    </p>

    <h5>Análisis Prescriptivo</h5>
    <p>
      Analiza los datos históricos para poder responder a la pregunta: <strong>¿Qué se debe hacer al respecto?</strong>. Los coches de conducción
      automáticos son un ejemplo de análisis prescriptivo: analizan el ambiente a su alrededor para poder tomar decisiones como cambiar de carril,
      frenar, acelerar, etc.
    </p>`,
    publicationDate: "Noviembre 06, 2024",
    readingTime: "8 min",
  },
  {
    slug: "tipos-de-datos",
    title: "Tipos de datos",
    topic: "Análisis de Datos",
    description:
      "El ecosistema del analista de datos incluye la infraestructura, software, herramientas, marcos y procesos utilizados para recopilar, limpiar, analizar, minar y visualizar datos. Según cómo esté estructurada la información, los datos se clasifican como estructurados, semi-estructurados y no estructurados.",
    content: `
      <h4>¿Qué es el Ecosistema del Analista de Datos?</h4> <br />
      <p>
        Un ecosistema de analista de datos incluye la infraestructura, el software, las herramientas, los marcos y los procesos utilizados para
        <strong>recopilar, limpiar, analizar, minar y visualizar datos.</strong>
      </p>
  
      <h4>Tipos de Datos</h4> <br />
      <p>
        Según la estructura de los datos, estos pueden clasificarse en:
      </p>
      <ul>
        <li><strong>Datos Estructurados:</strong> datos bien organizados en formatos que pueden almacenarse en bases de datos.</li>
        <li><strong>Datos Semi-Estructurados:</strong> datos parcialmente organizados y en parte en formato libre.</li>
        <li><strong>Datos No Estructurados:</strong> datos que no pueden organizarse convencionalmente en filas y columnas.</li>
      </ul>
  
      <h4>Formatos de Archivo</h4> <br />
      <p>
        Los datos vienen en una amplia variedad de formatos de archivo, como archivos de texto delimitados, hojas de cálculo, XML, PDF y JSON, cada uno con su propia lista de beneficios y limitaciones.
      </p>
  
      <h4>Fuentes de Datos</h4> <br />
      <p>
        Los datos se extraen de múltiples fuentes, desde bases de datos relacionales y no relacionales hasta APIs, servicios web, flujos de datos, plataformas sociales y dispositivos sensores.
      </p>
  
      <h4>Repositorio de Datos</h4> <br />
      <p>
        Una vez que los datos son identificados y recopilados de diferentes fuentes, necesitan almacenarse en un repositorio de datos para prepararlos para el análisis. El tipo, formato y fuente de los datos influyen en el tipo de repositorio que se puede utilizar.
      </p>
  
      <h4>Lenguajes para Manipulación de Datos</h4> <br />
      <p>
        Los profesionales de datos necesitan una variedad de lenguajes que les ayuden a extraer, preparar y analizar los datos, entre ellos:
      </p>
      <ul>
        <li><strong>Lenguajes de Consulta:</strong> como SQL, utilizados para acceder y manipular datos de bases de datos.</li>
        <li><strong>Lenguajes de Programación:</strong> como Python, R y Java, para desarrollar aplicaciones y controlar su comportamiento.</li>
        <li><strong>Lenguajes de Shell y Scripting:</strong> como Unix/Linux Shell y PowerShell, para automatizar tareas operativas repetitivas.</li>
      </ul>`,
    publicationDate: "Noviembre 12, 2024",
    readingTime: "10 min",
  },
];

export default blogs;
