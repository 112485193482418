// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function FeaturesOne() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              IA & Machine Learning
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Mejora la toma de decisiones y la intervención humana mediante el aprendizaje avanzado
              y otras tecnologías relacionadas con la IA. Aprende y descubre tecnologías de
              reconocimiento del habla y procesamiento del lenguaje natural.
            </MKTypography>
            <MKTypography
              component="a"
              href="/"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              Saber más
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon="lightbulb"
                content={
                  <>
                    Mejora la toma de decisiones mediante análisis avanzados.
                    <br />
                    La IA permite obtener insights accionables.
                  </>
                }
              />
              <AboutUsOption
                icon="hearing"
                content={
                  <>
                    Aprovecha el reconocimiento del habla para automatizar tareas.
                    <br />
                    Facilita la interacción entre humanos y sistemas inteligentes.
                  </>
                }
              />
              <AboutUsOption
                icon="language"
                content={
                  <>
                    Descubre el procesamiento del lenguaje natural.
                    <br />
                    Interpreta y comprende el lenguaje humano de manera efectiva.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesOne;
