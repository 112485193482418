import MKBox from "components/MKBox";
import BlogPosts from "./sections/blog";
import FeaturesOne from "./sections/features";
import FooterThress from "./sections/footer";
import Header from "./sections/header";
import ApplicationOne from "./sections/services";

const Home = () => {
  return (
    <>
      <Header />
      <FeaturesOne />
      <MKBox id="blog">
        <BlogPosts />
      </MKBox>
      <MKBox id="services">
        <ApplicationOne />
      </MKBox>
      <FooterThress />
    </>
  );
};

export default Home;
