import FooterThress from "./sections/footer";
import Header from "./sections/header";

// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Information from "./sections/Information";
// import Team from "./sections/Team";
// import Featuring from "./sections/Featuring";
// import Newsletter from "./sections/Newsletter";

const AboutUs = () => {
  return (
    <>
      <Header />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        {/* <Team /> */}
        {/* <Featuring /> */}
        {/* <Newsletter /> */}
      </Card>
      <FooterThress />
    </>
  );
};

export default AboutUs;
